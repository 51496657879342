<template>
  <PageContainer :title="title" :back="actions.back" ref="pageContainer">
    <template v-slot:content>
      <template v-if="hasGroups && !loading">
        <Carrousel
          :category-name="value[0].category.code"
          :title="value[0].category.text"
          :entry-values="value"
          :key="index"
          v-for="(value, index) of grouped"
        />
      </template>

      <div class="page-segment margin-top" v-if="loading">
        <Skeleton
          class="margin-bottom"
          width="10%"
          height="21px"
          borderRadius="8px"
        />
        <div class="for-you-group-skeleton">
          <div v-for="index in 4" :key="index">
            <Skeleton
              class="margin-bottom-xs"
              width="100%"
              height="150px"
              borderRadius="8px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="100%"
              height="22px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="60%"
              height="15px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="30%"
              height="15px"
              borderRadius="16px"
            />
          </div>
        </div>
      </div>

      <DataZeroSimple
        v-if="!loading && hasGroups === 0"
        :text="$translations['for-you']['datazero-group']"
        :image="require('@/assets/icons/search/search-default.svg')"
      />
    </template>
  </PageContainer>
</template>

<script>
import { PageContainer, DataZeroSimple, Skeleton } from '@seliaco/red-panda'
import Carrousel from '@/views/for-you/components/v2/Carrousel'
import { mapGetters } from 'vuex'

export default {
  name: 'ForYouGroup',
  components: {
    PageContainer,
    Carrousel,
    DataZeroSimple,
    Skeleton
  },
  data () {
    const actions = {
      back: {
        callback: () => {
          if (this.$route.query.back) {
            this.$router.push({
              path: this.$route.query.back
            })
          } else {
            this.$router.replace({
              name: 'ForYou'
            })
          }
        }
      }
    }

    return {
      actions
    }
  },
  created () {
    if (!this.group.length) {
      this.$store.dispatch('forYou/setup')
    }

    if (this.$route.params?.group) {
      this.$store.dispatch('forYou/list', {
        params: {
          group: this.$route.params.group,
          page: 1,
          'per-page': 100
        }
      })
    } else {
      this.$router.replace({
        name: 'ForYou'
      })
    }
  },
  computed: {
    title () {
      if (this.group.length > 0) {
        return this.group.find((g) => g.code === this.$route.params.group).text
      }

      return ''
    },
    hasGroups () {
      if (this.grouped) {
        return Object.keys(this.grouped).length
      }

      return false
    },
    ...mapGetters({
      loading: 'forYou/loading',
      grouped: 'forYou/grouped',
      group: 'forYou/group'
    })
  }
}
</script>

<style lang="sass" scoped>
.for-you-group-skeleton
  display: grid
  gap: 8px
  grid-template-columns: repeat(2,1fr)
  @media (min-width: 480px)
    grid-template-columns: repeat(4, 1fr)
</style>
