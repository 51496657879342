<template>
  <div
    class="page-segment padding-x-zero carrousel"
    :id="categoryName"
    v-if="hasItems"
  >
    <div class="carrousel-header">
      <div class="heading-small padding-left" v-text="title" />
      <div
        class="content-title text-purple cursor-pointer"
        @click="goToCategory"
        v-text="$translations.general['see-more']"
        v-if="items.length >= 10"
      />
    </div>

    <template v-if="!isMobile && showArrows">
      <Button
        class="carrousel-button-left"
        type="circle"
        size="circle-small"
        icon="icon-arrow-big-outline-left"
        color="white"
        :disable="!enableLeft"
        @clicked="moveCarrousel('prev')"
      />
      <Button
        class="carrousel-button-rigth"
        type="circle"
        size="circle-small"
        icon="icon-arrow-big-outline-right"
        color="white"
        :disable="!enableRight"
        @clicked="moveCarrousel('next')"
      />
    </template>

    <div
      class="carrousel-wrapper"
      :id="categoryName + '-wrapper'"
      :class="{ 'carrousel-scroll': isMobile }"
    >
      <template v-if="!loading && items">
        <DisplayCard
          class="carrousel-item"
          id="carrousel-item"
          v-for="(item, index) in items.slice(0, 10)"
          :key="index"
          :display-config="displayConfig(item)"
          :cover-icon="item.icon"
          :duration-icon="item.content_format.icon"
          :min-max-width="true"
          @click="onClick(item)"
        />
      </template>

      <template v-if="loading && hasItems">
        <div class="margin-left" v-for="(v, i) in 10" :key="i">
          <Skeleton
            class="margin-top-xs"
            width="240px"
            height="120px"
            borderRadius="16px"
          />
          <Skeleton
            class="margin-top-xs"
            width="240px"
            height="44px"
            borderRadius="16px"
          />
          <Skeleton
            class="margin-top-xs"
            width="140px"
            height="16px"
            borderRadius="16px"
          />
          <Skeleton
            class="margin-top-xs"
            width="180px"
            height="15px"
            borderRadius="16px"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { DisplayCard, Skeleton, Button } from '@seliaco/red-panda'
import MediaContentMixin from '@/mixins/media-content-mixin'
import ResponsiveMixin from '@/mixins/responsive.mixin'
import { ForYouEvent } from '@/types/events'
import { mapGetters } from 'vuex'

export default {
  name: 'CarrouselForYou',
  mixins: [MediaContentMixin, ResponsiveMixin],
  components: {
    DisplayCard,
    Skeleton,
    Button
  },
  props: {
    categoryName: String,
    title: String,
    entryValues: Array,
    customFilter: Object
  },
  data () {
    return {
      showArrows: false,
      maxPosition: null,
      enableLeft: false,
      enableRight: false,
      currentStep: 0,
      touchstartX: 0,
      touchendX: 0,
      controller: null
    }
  },
  created () {
    const hasItems = this.entries({
      category: this.categoryName
    })

    if (this.categoryName && hasItems.length === 0) {
      this.$store.dispatch('forYou/list', {
        params: {
          category: this.categoryName,
          page: 1,
          per_page: 12
        }
      })
    }
  },
  destroyed () {
    if (this.controller) {
      this.controller.abort()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.setConfig()
      this.setListeners()
    },
    setConfig () {
      this.maxPosition = this.items.length - 1
      this.enableRight = this.items.length >= 5
    },
    setListeners () {
      this.controller = new AbortController()
      this.carrousel = document.getElementById(this.categoryName)
      const wrapper = document.getElementById(`${this.categoryName}-wrapper`)
      this.maxPosition = this.items.length - 1
      this.enableRight = this.items.length >= 5

      if (this.carrousel) {
        this.carrousel.addEventListener(
          'mouseover',
          () => {
            this.enableLeft = wrapper.scrollLeft > 20 && this.currentStep !== 0
            this.showArrows = true
          },
          { signal: this.controller.signal }
        )
        this.carrousel.addEventListener(
          'mouseleave',
          () => {
            this.showArrows = false
          },
          { signal: this.controller.signal }
        )

        wrapper.addEventListener(
          'scroll',
          (e) => {
            if (!this.isMobile) {
              e.preventDefault()
            }
          },
          { signal: this.controller.signal }
        )
      }
    },
    moveCarrousel (direction) {
      const wrapper = document.getElementById(`${this.categoryName}-wrapper`)
      const carrouselItem = wrapper.firstElementChild

      if (!carrouselItem) {
        console.error('There is no elements in carrousel')
        return
      }

      const itemWidth = carrouselItem.clientWidth + 10

      let operation
      switch (direction) {
        case 'prev':
          this.currentStep -= 1
          this.currentStep = Math.max(0, this.currentStep)
          operation = itemWidth * this.currentStep
          break
        case 'next':
        default:
          this.currentStep += 1
          this.currentStep = Math.min(
            this.currentStep,
            wrapper.scrollWidth / itemWidth - 1
          )
          operation = itemWidth * this.currentStep
          break
      }

      wrapper.scroll({
        left: operation,
        behavior: 'smooth'
      })

      this.enableLeft = operation > 0
      this.enableRight = operation < wrapper.scrollWidth - wrapper.clientWidth
    },
    onClick (item) {
      const body = {
        page: this.$route.name,
        origin: 'Para ti',
        component: 'Carrousel,Card',
        ...item
      }
      ForYouEvent.contentCardOpen(body)
      this.showDetail(item)
    },
    goToCategory () {
      let category = this.categoryName
      const group = this.$route.params.group

      if (!category) {
        category = this.entryValues[0].category.code
      }

      if (group) {
        return this.$router.push({
          name: 'ForYouCategoryGroup',
          params: { group, category },
          query: {
            back: this.$route.fullPath
          }
        })
      }

      this.$router.push({
        name: 'ForYouCategory',
        params: { category },
        query: {
          back: this.$route.fullPath
        }
      })
    }
  },
  computed: {
    hasItems () {
      return Boolean(this.items?.length > 0)
    },
    items () {
      if (this.entryValues?.length) {
        return this.entryValues
      }
      if (this.customFilter) {
        return this.entries({
          category: this.categoryName,
          [this.customFilter.type]: this.customFilter.code
        })
      }
      return this.entries({
        category: this.categoryName
      })
    },
    ...mapGetters({
      entries: 'forYou/entriesByTypeAndValue',
      categories: 'forYou/categories',
      loading: 'forYou/loading'
    }),
    displayConfig () {
      return (value) => {
        const category = value.content_type.text
        const duration = this.durationFormat(value.duration)

        return {
          ...value,
          duration: `${category} • ${duration}`
        }
      }
    }
  },
  watch: {
    items: {
      handler () {
        this.init()
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="sass" scoped>
.carrousel
  display: flex
  flex-direction: column
  gap: 14px
  position: relative
  &-header
    display: flex
    justify-content: space-between
    align-items: center
    padding-right: 16px

  &-scroll
    overflow-x: auto !important
    // firefox hide scrollbar
    scrollbar-width: initial
    &::-webkit-scrollbar
      display: block

  &-wrapper
    display: flex
    gap: 10px
    width: 100%
    position: relative
    overflow-x: hidden
    overflow-y: hidden

    // firefox hide scrollbar
    scrollbar-width: none

    // chrome hide scrollbar
    &::-webkit-scrollbar
      display: none

  &-item
    &:first-child
      margin-left: 16px

    &:last-child
      margin-right: 16px

  &-button-rigth,
  &-button-left
    position: absolute
    top: 50%
    transform: translateY(-30%)
    z-index: 2

  &-button-rigth
    right: -10px
  &-button-left
    left: -10px

@media (min-width: 1260px)
  .carrousel
    margin: 16px !important

@media (min-width: 1040px)
  .carrousel
    margin: 10px !important
</style>
