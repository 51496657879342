import { render, staticRenderFns } from "./ForYouGroup.vue?vue&type=template&id=4c93cf98&scoped=true"
import script from "./ForYouGroup.vue?vue&type=script&lang=js"
export * from "./ForYouGroup.vue?vue&type=script&lang=js"
import style0 from "./ForYouGroup.vue?vue&type=style&index=0&id=4c93cf98&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c93cf98",
  null
  
)

export default component.exports